import React, { Component } from 'react';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import Bg from '../../static/images/pc/bg-1.jpg'
import Bgs from '../../static/images/pc/bg-2.jpg'
import Bgt from '../../static/images/pc/bg-3.jpg'
import Bgf from '../../static/images/pc/bg-4.jpg'
import Bgmobilef from '../../static/images/mobile/bg-m-1.jpg'
import Bgmobiles from '../../static/images/mobile/bg-m-2.jpg'
import Logo from '../../static/images/pc/web-logo-icon.png'
import Season from '../../static/images/pc/Season2-icon.png'
import Fb from '../../static/images/pc/Web-FB-Login.png'
import Dlgame from '../../static/images/pc/DL-game.png'
import Bannerimg from '../../static/images/pc/web-banner.png'
import Frame from '../../static/images/pc/Web-Frame.png'
import Title from '../../static/images/pc/title-FB.png'
import Group from '../../static/images/mobile/join-group-icon.png'
import Fbicon from '../../static/images/pc/web-FB-icon.png'
import Lineicon from '../../static/images/pc/web-Line-icon.png'
import Groupicon from '../../static/images/pc/web-FB-group-icon.png'
import Popup from '../../static/images/pc/pop-up.png'
import Popupfb from '../../static/images/pc/facebook.png'
import Popupgroup from '../../static/images/pc/FB-group.png'
import Popupdownload from '../../static/images/pc/download.png'
import Popupmobile from '../../static/images/mobile/pop-up-mobile.png'

class App extends Component {

  state = {
    link : "",
    isDisabled: false
  }

  componentDidMount() {
    if (this.props.location.hash == "#_=_") {
      this.setState({isDisabled: true})
    }
  }


  responseFacebook = (response) => {
    console.log(response,'facebook-response')
    const uid = response.id
    const url = `https://dummy-ws.digitopolis.studio/fallback.html?uid=${uid}&AccountType=facebook`
    console.log(url,'facebook-url')
    const dynamicLinkInfo = {
      domainUriPrefix: "dummypueak.page.link",
      link: url ,
      androidInfo: {
        androidPackageName: "com.digitopolisstudio.dummyth"
      },
      iosInfo: {
        iosBundleId: "com.digitopolisstudio.dummypueak"
      }
  }
    window.fbq('track', 'CompleteRegistration')
    axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDC3OGn8t7dIBKyheaO56Vy22e4D-eyyUQ`, { dynamicLinkInfo })
    .then(res => {
      this.setState({
        link: res.data.shortLink
    })
      setTimeout(this.redirect, 100)
    })
  }

  click = () => {
    this.setState({isDisabled: true})
  }

  redirect = () => {
    window.location.href = this.state.link;
  }

  render() {
  return (
    <Con className="container-fluid" >
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.3&appId=704411143325931&autoLogAppEvents=1"></script>
    <Background>
      <Head>
      <Buffaloicon>
        <a href="https://www.facebook.com/dummypueak/"><Fbbufficon/></a>
        <a href="https://www.facebook.com/groups/394957764684847/"><Groupbufficon/></a>
        <a href="https://play.google.com/store/apps/details?id=com.digitopolisstudio.dummyth" ><Dlbufficon/></a>
      </Buffaloicon>
        <Contentdm className="row">
          <Contentlink className="row">
              <Linesmallicon href="https://line.me/ti/g2/YPR7nNMw4f0uvqn3uIQqPA" />
              <Groupsmallicon
              href="https://www.facebook.com/groups/394957764684847/"
              />
              <Fbsmallicon
              href="https://www.facebook.com/dummypueak/"
              />
          </Contentlink>
          <Contentlogo className="row">
            <Logoicon/>
            <Seasonicon/>
          </Contentlogo>
        </Contentdm>
        <Contentlogin className="row">
          <Confb className="row">
            <FacebookLogin
                isDisabled={this.state.isDisabled}
              appId="309534235809023"
              autoLoad={false}
              fields="name,email,picture"
              callback={this.responseFacebook}
                onClick={this.click}
              render={renderProps => (
              <Facebook onClick={renderProps.onClick} />
                )}
            />
          </Confb>
          <Condl className="row">
          <a href="https://dummypueak.page.link/Posx"><Dlgameicon/></a>
          </Condl>
        </Contentlogin>
        <Contentbanner className="row">
          <Banner/>
        </Contentbanner>
        <Condeventhead className="row">
          <Eventheader/>
        </Condeventhead>
        <Contentevent className="row">
          <Eventbox>
          <Contentplugin className="fb-page" data-href="https://www.facebook.com/dummypueak/"
          data-tabs="timeline"
          data-width="500"
          data-height="350"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true">
          <blockquote cite="https://www.facebook.com/dummypueak/"
          className="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/dummypueak/">ดัมมี่เผือก</a></blockquote></Contentplugin>
        </Eventbox>
        <Eventboxmobile>
          <Contentpluginmobile className="fb-page" data-href="https://www.facebook.com/dummypueak/"
          data-tabs="timeline"
          data-width="210"
          data-height="190"
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true">
          <blockquote cite="https://www.facebook.com/dummypueak/"
          className="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/dummypueak/">ดัมมี่เผือก</a></blockquote></Contentpluginmobile>
        </Eventboxmobile>
        <Buffalomobile/>
        </Contentevent>
      </Head>
      <Coppyright className="row">
      <Joingroup
      href="https://www.facebook.com/groups/394957764684847/"
      />
      <Coppyrighttext>Copyright © 2016 Digitopolis Studio</Coppyrighttext>
      </Coppyright>
    </Background>
    <Backgrounds>
      <Head/>
      </Backgrounds>
      <Backgroundt>
      <Head/>
      </Backgroundt>
      <Backgroundf>
      <Head/>
      </Backgroundf>
    </Con>
  );
}
}

export default App;

const Con = styled.div`
  background-color: #000000;
  overflow-x: hidden;
  width: 100vw;
  padding: 0px !important ;
  @media (max-width: 575.98px) {
    height: 1100px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 1200px;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    height: 2300;
  }
`
const Head = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
`

const Contentplugin = styled.div`
  height: 20rem;
  width: 36rem;
`
const Contentpluginmobile = styled.div`
  height: 10rem;
  width: 20rem;
`
const Contentdm = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 2%;
  position: relative;

`
const Coppyright = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 2%;
  position: relative;
  justify-content: center;
  margin-left: 0% !important;
  margin-right: 0% !important;
  height: 10%;
  width: 100%;
  @media (max-width: 575.98px) {
    height: 20%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      height: 8%;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    height: 8%;
  }
`
const Coppyrighttext = styled.div`
  color: #ffffff;
  bottom: 0;
  position:absolute;
  font-size: 1em;
  @media (max-width: 575.98px) {
    font-size: 0.5em;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    font-size: 0.5em;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    font-size: 1em;
  }
`

const Contentlink = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 1%;
  position: relative;
  justify-content:flex-end;
  height: 4%;
  width: 100vw;
  @media (max-width: 575.98px) {
    height: 3rem;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 3rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    height: 1%;
  }
`
const Contentlogo = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 2%;
  position: relative;
  height: 4%;
  width: 100vw;
  margin-top: -2%;
  @media (max-width: 575.98px) {
    height: 4rem;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 4rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 0%;
  }
`
const Backgroundm = styled.div`
  @media (max-width: 575.98px) {
    background-image: url(${Bgmobilef});
    background-repeat: no-repeat;
  background-size:100% 100%;
  margin-bottom: -2.75px;
    height: 1100px;
    width: 100vw;
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    background-image: url(${Bgmobilef});
    background-repeat: no-repeat;
  background-size:100% 100%;
  margin-bottom: -2.75px;
    height: 1200px;
    width: 100vw;
    display: none;
  }
`
const Background = styled.div`
  height: 650px;
  width: 100vw;
  background-image: url(${Bg});
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin-bottom: -2.75px;
  @media (max-width: 575.98px) {
    background-image: url(${Bgmobilef});
    height: 550px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    background-image: url(${Bgmobilef});
    height: 600px;

  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    background-image: url(${Bgmobilef});
    height: 1150px;
  }
`
const Backgrounds = styled.div`
  height: 650px;
  width: 100vw;
  background-image: url(${Bgs});
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin-bottom: -2.75px;
  @media (max-width: 575.98px) {
    background-image: url(${Bgmobiles});
    height: 550px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    background-image: url(${Bgmobiles});
    height: 600px;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    background-image: url(${Bgmobiles});
    height: 1150px;
  }
`
const Backgroundt = styled.div`
  height: 650px;
  width: 100vw;
  background-image: url(${Bgt});
  background-repeat: no-repeat;
  background-size:100% 100%;
  margin-bottom: -3px;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    display: none;
  }
`
const Backgroundf = styled.div`
  height: 650px;
  width: 100vw;
  background-image: url(${Bgf});
  background-repeat: no-repeat;
  background-size:100% 100%;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    display: none;
  }
`
const Fbbufficon = styled.button`
    width: 11.25rem;
    height: 2.5rem;
    margin-left: 8%;
    margin-right: 5%;
    top: 69%;
    background-color: Transparent;
    border: none;
    pointer-events: fill;
    background-image: url(${Popupfb});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position:relative;
    z-index: 1;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 0%;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) { 
    /* left: 69%; */
  }
`
const Groupbufficon = styled.button`
    width: 12.25rem;
    height: 3rem;
    margin-right: 5%;
    top: 66%;
    background-color: Transparent;
    border: none;
    pointer-events: fill;
    background-image: url(${Popupgroup});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position:relative;
    z-index: 1;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 0%;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) { 
  }
`
const Dlbufficon = styled.button`
    width: 11rem;
    height: 1.8rem;
    margin-left: 10%;
    margin-right: 5%;
    top: 64%;
    background-color: Transparent;
    border: none;
    pointer-events: fill;
    background-image: url(${Popupdownload});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position:relative;
    z-index: 1;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 0%;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) { 
  }
`

const Buffaloicon = styled.div`
    width: 13rem;
    height: 23.5rem;
    margin-left: 6%;
    margin-right: 5%;
    left: 75%;
    top: 20%;
    background-image: url(${Popup});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position:fixed;
    z-index: 1;
  @media (max-width: 575.98px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    left: 60%;
    margin-top: 0%;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    left: 69%;
  }
`
const Buffalomobile = styled.div`
  @media (max-width: 575.98px) {
    width: 6rem;
    height: 10rem;
    left: 68%;
    top: 7%;
    background-image: url(${Popupmobile});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    width: 5rem;
    height: 9rem;
    left: 60%;
    top: 25%;
    background-image: url(${Popupmobile});
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
  }
`
const Logoicon = styled.div`
    width: 10rem;
    height: 10rem;
    margin-left: 6%;
    margin-right: 5%;
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-size:100% 100%;
  @media (max-width: 575.98px) {
    background-image: url(${Logo});
    height: 4rem;
    width: 4rem;
    right: 60%;
    position:absolute;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    background-image: url(${Logo});
    height: 4rem;
    width: 4rem;
    right: 60%;
    position:absolute;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 0%;
  }
`
const Groupsmallicon = styled.a`
   width: 7rem;
    height: 4rem;
    margin-right: -1%;
    margin-left: -1%;
    margin-top: 6px;
    background-image: url(${Groupicon});
    background-repeat: no-repeat;
    background-size:100% 100%;
    pointer-events: fill;
  @media (max-width: 575.98px) {
    background-image: url(${Groupicon});
    height: 2.75rem;
    width: 5rem;
    margin-top: 4px;
    margin-right: -18px;
    margin-left: -18px;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    background-image: url(${Groupicon});
    height: 2.75rem;
    width: 5rem;
    margin-top: 4px;
    margin-right: -18px;
    margin-left: -18px;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 5px;
    margin-right: -25px;
    margin-left: -25px;
  }
`
const Fbsmallicon = styled.a`
   width: 3rem;
    height: 3rem;
    margin-right: 1%;
    margin-top: 12px;
    background-image: url(${Fbicon});
    background-repeat: no-repeat;
    background-size:100% 100%;
    pointer-events: fill;
  @media (max-width: 575.98px) {
    background-image: url(${Fbicon});
    height: 2rem;
    width: 2rem;
    margin-top: 9px;
    margin-right: 0px;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    background-image: url(${Fbicon});
    height: 2rem;
    width: 2rem;
    margin-top: 9px;
    margin-right: 0px;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 12px;
  }
`
const Linesmallicon = styled.a`
   width: 3rem;
  height: 3rem;
  margin-left: 6%;
  margin-top: 12px;
  background-image: url(${Lineicon});
  background-repeat: no-repeat;
  background-size:100% 100%;
  pointer-events: fill;
  @media (max-width: 575.98px) {
    background-image: url(${Lineicon});
    height: 2rem;
    width: 2rem;
    margin-top: 9px;
    margin-left: 0px;
  }
  @media (min-width: 576px) and (max-width: 767.98px)  {
    background-image: url(${Lineicon});
    height: 2rem;
    width: 2rem;
    margin-top: 9px;
    margin-left: 0px;
  }
  @media (min-width: 768px) and (max-width: 991.98px)  {
    margin-top: 12px;
  }
`
const Seasonicon = styled.div`
  height: 12rem;
  width: 20rem;
  /* margin-top: 3%; */
  background-image: url(${Season});
  background-repeat: no-repeat;
  position:absolute;
  left:calc(50% - 8rem);
  background-size:100% 100%;
  @media (max-width: 575.98px) {
    background-image: url(${Season});
    height: 4rem;
    width: 7rem;
    margin-right:2%;
    left : 62%;
    margin-top: 7px;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
    background-image: url(${Season});
    height: 4rem;
    width: 7rem;
    left : 70%;
    margin-right:2%;
    margin-top: 7px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-top: 0%;
    height: 10rem;
    width: 16rem;
    left:calc(50% - 6rem);
  }
`
const Contentlogin = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 2%;
`
const Confb = styled.div`
height: 23rem;
position:relative;
  @media (max-width: 575.98px) {
    height: 11.5rem;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 11.5rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 23.5rem;
  }
`
const Condl = styled.div`
height: 13rem;
position:relative;
  @media (max-width: 575.98px) {
    height: 5.25rem;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 5.25rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 10.5rem;
  }
`
const Facebook = styled.button`
  height: 5rem;
  width: 22rem;
  background-image: url(${Fb});
  background-repeat: no-repeat;
  background-color: Transparent;
  border: none;
  bottom: 0;
  position:absolute;
  left:calc(50% - 10rem);
  background-size:100% 100%;
  @media (max-width: 575.98px) {
    height: 1.7rem;
    width: 8rem;
    left:calc(50% - 3.75rem);
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      height: 1.7rem;
      width: 8rem;
      left:calc(50% - 3.75rem);
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 3.5rem;
    width: 16rem;
    left:calc(50% - 7.5rem);
  }
`
const Dlgameicon = styled.button`
    height: 5rem;
    width: 22rem;
    background-image: url(${Dlgame});
    background-repeat: no-repeat;
    background-color: Transparent;
    border: none;
    bottom: 0;
    position:absolute;
    left:calc(50% - 10rem);
    background-size:100% 100%;
  @media (max-width: 575.98px) {
    height: 1.5rem;
      width: 8rem;
      left:calc(50% - 3.75rem);
      bottom: 0;
    }
    @media (min-width: 576px)  and (max-width: 767.98px)  {
      height: 1.5rem;
      width: 8rem;
      left:calc(50% - 3.75rem);
      bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 3rem;
    width: 16rem;
    left:calc(50% - 7.5rem);
    bottom: 0;
  }
`
const Contentbanner = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 50rem;
  position:relative;
  height: 70rem;
  @media (max-width: 575.98px) {
    height: 20.5rem;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 20.5rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 42rem;
  }

`
const Banner = styled.div`
    height: 60rem;
    width: 120rem;
    background-image: url(${Bannerimg});
    background-repeat: no-repeat;
    position:absolute;
    left:calc(50% - 65rem);
    bottom: 0;
    position:absolute;
    overflow-x: hidden;
    justify-content:center;
    background-size:110% 100%;
  @media (max-width: 575.98px) {
    height: 15rem;
    width: 32rem;
    left:calc(50% - 15.5rem);
    background-size:100% 100%;
    }
    @media (max-width: 767.98px) and (max-width: 767.98px)  {
      height: 15rem;
    width: 35rem;
    left:calc(50% - 16.5rem);
    background-size:100% 100%;
    }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 26rem;
    width: 68rem;
    left:calc(50% - 33rem);
    background-size:100% 100%;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    height: 45rem;
    width: 100rem;
    left:calc(50% - 53rem);
  }
`
const Condeventhead = styled.div`
  height: 12rem;
  position:relative;
  .fb-page, .fb-page iframe[style], .fb-page span {
      width: 100% !important;
  }
  .fb-comments, .fb-comments iframe[style], .fb-comments span {
    width: 100% !important;
  }
  @media (max-width: 575.98px) {
    height: 0rem;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 0rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 10rem;
  }
`
const Eventheader = styled.div`
    height: 10rem;
    width: 40rem;
    background-image: url(${Title});
    background-repeat: no-repeat;
    border: none;
    bottom: 0;
    position:absolute;
    left:calc(50% - 20rem);
    background-size:100% 100%;
  @media (max-width: 575.98px) {
    display:none;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      display:none;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 8rem;
    width: 30rem;
    left:calc(50% - 15rem);
  }
`
const Eventbox= styled.div`
    height: 25rem;
    width: 36rem;
    background-image: url(${Frame});
    background-repeat: no-repeat;
    border: none;
    bottom: 0;
    padding : 2rem;
    display:flex;
    flex-direction: row;
    justify-content:center;
    position:absolute;
    left:calc(50% - 18rem);
    background-size:100% 100%;
  @media (max-width: 575.98px) {
    display: none;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      display: none;
  }
`
const Eventboxmobile = styled.div`
  @media (max-width: 575.98px) {
    height: 14rem;
    width: 15rem;
    left:calc(50% - 7.5rem);
    background-image: url(${Frame});
    background-repeat: no-repeat;
    border: none;
    bottom: 0;
    padding : 1rem;
    display:flex;
    flex-direction: row;
    justify-content:center;
    position:absolute;
    background-size:100% 100%;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 14rem;
    width: 20rem;
    left:calc(50% - 10rem);
    background-image: url(${Frame});
    background-repeat: no-repeat;
    border: none;
    bottom: 0;
    padding : 1rem;
    display:flex;
    flex-direction: row;
    justify-content:center;
    position:absolute;
    background-size:100% 100%;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    display: none;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    display: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`
const Joingroup = styled.a`
  @media (max-width: 575.98px) {
    height: 2rem;
    width: 10rem;
    left:calc(50% - 5rem);
    background-image: url(${Group});
    background-repeat: no-repeat;
    background-color: Transparent;
    border: none;
    background-size:100% 100%;
    pointer-events: fill;
    margin-top: 10%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      height: 2rem;
    width: 10rem;
    left:calc(50% - 5rem);
    background-image: url(${Group});
    background-repeat: no-repeat;
    background-color: Transparent;
    border: none;
    background-size:100% 100%;
    margin-top: 10%;
  }
`
const Contentevent = styled.div`
  height: 25rem;
  position:relative;
  @media (max-width: 575.98px) {
    height: 15rem;
    }
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 14rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    height: 27rem;
  }
`